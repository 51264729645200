body {
  background-color: #EBE8D8 !important;
  /* margin-left: 20%;
  margin-right: 20%; */
  text-align: center;
  position: absolute;
  width: 100vw;
}
input{
  margin-left: 3%;
  font-family: 'Sarabun';
  border-color: lightgray;
  box-shadow: transparent;
}
.enter {
padding: 1px 15% 1px 15%;
border-radius: 100px;
border-color: transparent;
color: #2B313B;
background-color: #FFDA1A;
margin-left: auto;
margin-right: auto;
font-display: auto;
}
.enter:hover {
  border-color: transparent;
  color: #2B313B;
  background-color: #f8cf02;
}
.enter:focus {
  border-color: transparent;
  color: #2B313B;
  background-color: #FFDA1A;
}
.pic1 {
  width: 15%;
  height: 15%;
  margin-left: 87%;
  margin-top: -12%;
}
.pic2 {
  width: 15%;
  height: 15%;
  margin-left: -90%;
  margin-top: -15%;
}
.back {
  margin-left: -80%;
  margin-top: 8%;
  color: #2B313B;
  font-weight: bold;
}
@media screen and (min-width: 575px) {
  /* .pic1{
    margin-left: ;
  } */
  .title {
    /* margin-left: 2%; */
    align-items: center;
    text-align: center;
  }
  .enter {
    margin-left: 35vw;
  }
}
