body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

@font-face {
  font-family: 'Hatsch';
  src: local('Hatsch'), url(./fonts/Hatsch.ttf) format('truetype');
}

@font-face {
  font-family: 'lil';
  src: local('lil'), url(./fonts/lilstuart.ttf) format('truetype');
}

@font-face {
  font-family: 'cormo';
  src: local('cormo'), url(./fonts/Cormorant.ttf) format('truetype');
}

@font-face {
  font-family: 'sarabun';
  src: local('sarabun'), url(./fonts/Sarabun.ttf) format('truetype');
  /* background-color: #EBE8D8; */
  padding: 0;
  /* text-align: center; */
}

@font-face{
  font-family: 'Sarabun';
  src: url('./fonts/Sarabun-Regular.ttf') format('truetype');
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 100px;
  /* line-height: 100px; */
  letter-spacing: 0px;
  opacity: 1;
}
@font-face{
  font-family: 'SarabunExtraBold';
  src: url('./fonts/Sarabun-ExtraBold.ttf') format('truetype');
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  opacity: 1;
}
@font-face{
  font-family: 'SarabunSemiBold';
  src: url('./fonts/Sarabun-SemiBold.ttf') format('truetype');
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 100px;
  line-height: 100px;
  letter-spacing: 0px;
  opacity: 1;
}
@font-face {
  font-family: 'Lil Stuart';
  src: url('./fonts/lil\ stuart.ttf') format('truetype');
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  font-size: 408px;
  line-height: 136px;
  opacity: 1;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    
}

